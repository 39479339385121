.listContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.pageTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.pageHelpTitle {
  font-size: 14px;
  line-height: 16px;
  color: #6d7f92;
  margin-bottom: 1.5rem;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
  }
}

.filterBar {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
}
.compare {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}
.fitlerTxt {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
}

.chartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 50%;

  .box {
    height: 180px;
    width: 180px;
  }
}

.formulaFilter {
  width: 200px;
}

.filter {
  display: flex;
  // align-items: center;
  flex-direction: column;

  label {
    margin: 0 10px 5px 3px;
  }
}

.inputSearch {
  display: flex;
  background: #fff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  border-radius: 30px;
  height: 32px;
  width: 300px;
  align-items: center;

  &_icon {
    color: #b7bdc3;
    cursor: pointer;
    font-size: 1.2rem;
    display: inherit;
    margin: auto 1rem;
  }

  input {
    width: 240px;
    border: none;
    outline: none;
    margin: 0;
    color: #3c4e64;
  }
}

.tableContainer {
  overflow-x: scroll;
  overflow-y: hidden;
  height: max-content;
}

.table {
  border-collapse: collapse;
  width: 100%;

  th {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding: 10px 14px;

    i {
      cursor: pointer;
      color: #6d7f92;

      &.iconActive {
        color: #29394d;
      }
    }

    .sortBox {
      margin-left: 5px;
    }
  }

  tr {
    background: #fafbfb;
    border-bottom: 2px solid #fff;
    border-radius: 2px;
  }

  .selectRowAction {
    background-color: #f3faff;
    td {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
  }

  td {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding: 10px 14px;

    &.centerCell {
      text-align: center;
    }

    .cellIcon {
      transform: rotate(90deg);
      cursor: pointer;
      font-size: 14px;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &:hover {
        background-color: #f1f2f4;
      }

      &.active {
        background-color: #18a0fb;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}

.status {
  border-radius: 4px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  &.pending {
    background: #ffecbf;
    color: #e39f00;
  }

  &.validated {
    background: #dcf5ec;
    color: #02af8e;
  }

  &.rejected {
    background: #ffd7da;
    color: #fe3745;
  }
}

.nowrap {
  white-space: nowrap;
}

.dropDownContainer {
  position: relative;
}
.menuDropdown {
  position: absolute;
  right: 2.7rem;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #18a0fb;
  border-radius: 4px;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  background: #fff;
  z-index: 1005;
  width: 200px;

  &:before {
    content: "";
    right: -10px;
    border-color: transparent transparent transparent #18a0fb;
    border-style: solid;
    border-width: 7px 0 7px 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  li {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    line-height: 1.6;

    &:hover {
      background: #f8f9fa;
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.btmBorder {
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
}

.tabForms {
  display: flex;
  margin: 0 -20px 20px -20px !important;
  padding: 0;
  .active {
    color: #18a0fb;
  }

  li {
    height: 26px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #6d7f92;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-left: 1px solid #d8dde2;
    cursor: pointer;
    &:first-child {
      border: none;
    }
  }
}

.toolTitle {
  color: #fff;
}

.toolLines {
  list-style-type: circle !important;
  padding: 10px;
}

.successBtn {
  display: flex;
  align-items: center;
  font-weight: 500;
  text-align: center;
  background-color: #06d9b1;
  color: #fff;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  border: 1px solid transparent;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 5px;
}

.persons {
  position: relative;
  display: block;

  span {
    position: absolute;
    top: -10px;
    right: -15px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #fff;
    background-color: #18a0fb;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.disablePerson {
  color: #ccc;
  cursor: not-allowed;
}

.selectAction {
  color: #18a0fb;
  cursor: pointer;
  margin-left: 10px;
}

.orderAction {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  line-height: 16px;
}

.progress {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #6d7f92;
  text-transform: initial;
  min-width: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &_bar {
    height: 4px;
    width: 200px;
    background: #c4c4c4;
    border-radius: 20px;
    position: relative;
    text-align: center;
    margin-top: 5px;
  }

  &_value {
    position: absolute;
    left: 0;
    top: 0;
    height: 4px;
    background: #18a0fb;
    border-radius: 20px;
  }
}

.copyIcon {
  cursor: pointer;
  font-size: 14px;
  color: #6d7f92;
}
